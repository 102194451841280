<template>
  <div class="viewContainer">
    <v-container class="auth-container pa-1">
      <v-card v-if="loading" flat tile class="content-tabs-card pa-5 mb-0 mb-md-12">
        <v-progress-linear :indeterminate="true" color="primary"></v-progress-linear>
      </v-card>
      <v-card v-if="!loading" class="content-tabs-card px-8 py-12 rounded">
        <v-card v-if="type == 'affiliate'" flat tile> 
          <v-list-item >
            <v-list-item-avatar
              size="100"
              color="grey"
            >
              <v-img
                v-if="item.cover"
                :src="item.cover"
              ></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6">
                {{ item.business_name }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption">
                <div class="d-flex justify-content-between align-center">
                  <span class="text-body-1">{{ item.short_description }}</span>
                </div>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <div class="mb-2">
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('register_points') }}:</span>
              <span class="text-h6">{{ formatNumber(item.points_for_invite) }} {{ $t('points') }}</span>
            </div>
          </div>
        </v-card>
        <v-card v-if="type == 'coupon'" flat tile>
          <v-img
            v-if="item.cover"
            class="rounded"
            :src="item.cover"
          />
          <v-card-title class="px-0">
            <div class="d-flex text-h6">
              <v-icon left>
                mdi-ticket-percent-outline
              </v-icon>
              <span class="">{{ item.coupon_title }}</span>
            </div>
            <v-spacer></v-spacer>
            <span class="text-body-1">{{ item.business_name }}</span>
          </v-card-title>
          <v-card-text class="pa-0">
            <div class="text-body-1 mb-3" style="white-space: pre-wrap;"
            v-if="item.coupon_description" v-html="item.coupon_description"
            ></div>
            <div class="mb-2">
              <div class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('discount') }}:</span>
                <span class="text-h6">{{ formatNumber(item.discount) }}</span>
              </div>

              <div v-if="item.no_point_use" class="d-flex justify-end align-center">
                <span class="text-h6">{{ $t('no_point_use') }}</span>
              </div>

              <div v-else class="d-flex justify-content-between align-center">
                <span class="text-body-1">{{ $t('redeem_points') }}:</span>
                <span class="text-h6">{{ formatNumber(item.point_use) }}</span>
              </div>

            </div>
            <div class="text-body-1">
              <span>{{ $t('expires') }}: {{ formatDate(item.coupon_expires_at, 'LL') }}</span>
            </div>
            <div 
              class="my-4" 
              v-if="item.coupon_terms_condition"
            > 
              <div class="text-subtitle-1 font-weight-medium">{{ $t('terms_and_conditions') }}</div>
              <div  
              class="text-body-1 info--text"  
              >
                {{ item.coupon_terms_condition }}
              </div>
            </div>
            <div v-if="item.coupon_contact_email" class="text-body-1">
              <div >{{ $t('contact_email') }}: {{ item.coupon_contact_email }}</div>
            </div>
            <div v-if="item.coupon_contact_phone" class="text-body-1">
              <div >{{ $t('contact_phone') }}: {{ item.coupon_contact_phone }}</div>
            </div>
            <div v-if="item.coupon_contact_etc" class="text-body-1">
              <div >{{ $t('contact_etc') }}: {{ item.coupon_contact_etc }}</div>
            </div>
          </v-card-text>
          <div class="mb-2">
            <div class="d-flex justify-content-between align-center">
              <span class="text-body-1">{{ $t('register_points') }}:</span>
              <span class="text-h6">{{ formatNumber(item.points_for_invite) }} {{ $t('points') }}</span>
            </div>
          </div>
        </v-card>
        <div class="text-center">
          <v-btn class="mt-8 no-caps" color="primary" rounded x-large depressed 
          :to="{ name: 'register',query: { affiliate: $route.query.token} }">
            {{ $t('link_register') }}
          </v-btn>
        </div>
      </v-card>
    </v-container>
  </div>
</template>
<script>
  export default {
    data: () => ({
      locale: 'th',
      loading: true,
      token: null,
      type: null,
      item: null
    }),
    created () {
      if(this.$route.query && this.$route.query.token){
        this.loading = true
        this.axios.post('/auth/get-data-affiliate',{
          token: this.$route.query.token
        })
        .then((response) => {
          this.loading = false
          if(response.data.status == 'success'){
            //console.log(response.data)
            this.type = response.data.type
            this.item = response.data.affiliate
          }
        })
        .catch((error) => {
          this.$root.$snackbar(this.$t('not_found'))
          this.$router.push({name: '404'})
        })
      }
      else{
        this.$router.push({name: '404'})
      }
    },
    methods: {
      initLink (token) {
        return window.location.origin + '/register?affiliate=' + token
      },
      formatNumber (number) {
        return new Intl.NumberFormat(this.locale.replace('_', '-')).format(number)
      },
      formatDate (dt, format) {
        if (dt === null) {
          dt = '-'
        } else {
          dt = (format == 'ago') ? this.moment(dt).fromNow() : this.moment(dt).format(format)
        }
        return dt
      },
    }
  }
</script>
